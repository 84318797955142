export default {
  titles: {
    app: {
      limitTitle: 'Limit',
      next_payment: "Keyingi to'lov",
      title: 'Balans',
      installments: {
        title: "Muddatli to'lovlar",
        number: "Muddatli to'lov raqami",
        fullyPaid: "To'liq to'langan",
        payment: 'Keyingi to’lov • {date}',
        noMainCard: 'Asosiy karta mavjud emas',
        notFound: {
          title: 'Sizda muddatli to’lov yo’q',
          active: "Sizda faol muddali to'lovlar mavjud emas",
          link: 'Alif Shopga o’tish',
        },
        show: "Barcha muddatli to'lovlarni ko'rish",
        hide: 'Yashirish',
      },
      applications: {
        notFound: {
          title: 'Sizda hech qanday ariza yoʻq',
        },
      },
      limit: {
        title: 'Mavjud limit',
        recoverBtn: 'Limitni tiklash',
        increaseBtn: 'Limitni oshirish',
        limitExhausted: 'Sizning limitingiz to’xtatildi. Uni qayta tiklashingiz mumkin',
        waiting: 'Limitni tiklash uchun so’rov yuborildi, SMS orqali javobni kuting',
        increaseLimitWaiting: 'Limitni oshirish uchun so’rov yuborildi, SMS orqali javobni kuting',
        clear: 'Tushunarli',
        question: 'Limitni qanday ishlatish mumkin?',
        rejected: 'Limitni tiklash bo’yicha so’rov rad etildi.Qaytadan urinib kuring!',
        increaseLimitRejected: 'Limitni oshirish bo’yicha so’rov rad etildi.',
        availableLimit: 'Mavjud limit',
        total_limit: 'Umumiy limit',
        used_limit: 'Sarflandi',
        modalBtn: 'Bu qanday ishlaydi?',
        modal: {
          title: 'Limit qanday ishlaydi',
          description:
            "Limit — bu bizning hamkorlarimizdan muddatli to'lovga tovar sotib olishingiz mumkin bo'lgan summa",
        },
      },
      status: {
        title: 'Sizning statusingiz',
      },
      shops: {
        title: 'Hamkorlarimiz do’konlarida limitingizni sarflashingiz mumkin',
        subTitle: 'Butun O’zbekiston bo’ylab 100+ do’konlarda muddatli to’lovga xarid qiling!',
        link: 'Hamma do’konlar',
      },
      reviewProgress: {
        title: 'Arizangizni qoldirganingiz uchun tashakkur',
        subTitle: "Biz arizani 5 daqiqa ichida ko'rib chiqamiz.",
        subTitle2: "Javobni SMS ko'rinishida olasiz.",
        awaiting: 'Tez orada limitga ega bo’lasiz.',
        hasLimit: "Sizning limitingiz {amount} so'm.",
        noLimit: "Limitingiz 0 bo'lsa ham, mahsulot tanlagan holda ariza jo'natishingiz mumkin",
      },
      returnBack: {
        reviewed: 'Saytga qaytib kirishingiz mumkin',
        canGoBack: 'Ariza rasmiylashtirilishini davom ettirish uchun qaytib kirishingiz mumkin',
        goBackAndChooseItem: "Saytga o'ting va tovar tanlang",
      },
    },
    shops: {
      title: 'Do’konlar',
    },
    profile: {
      title: 'Profil sozlamalari',
      password: {
        title: 'Parol o’zgartirish',
        requirements: {
          title: 'Parol talablari',
          subTitle: 'Parol quyidagi punktlarga mos kelishi kerak:',
          list: [
            'Lotin harflari',
            'Parol uzunligi kamida 8-ta belgi',
            'Kamida 1 bosh harf',
            'Kamida 1 kichik harf',
            'Kamida 1 son',
          ],
        },
        messages: {
          success: "Parolingiz muvaffaqiyatli o'zgartirildi",
        },
      },
      phone: {
        title: 'Telefon raqamni o’zgartirish',
        newPhone: 'Yangi raqam kiriting',
        myIdDescription: 'Aynan siz asosiy raqamni o’zgartirmoqchiligingizga amin bo’lishimiz kerak',
        errors: {
          myIdTimeout: {
            title: 'Identifikatsiya muddati tugadi',
            message: 'Iltimos, qaytadan o’ting',
          },
        },
      },
      photo: {
        title: 'Profil rasmi',
        subTitle: 'Fayl 3 MB dan ko’p bo’lmasligi kerak',
        move: 'Profil rasmingizni yangilash uchun faylni  tortib keling',
        messages: {
          success: 'Profil fotosurati muvaffaqiyatli yangilandi',
          delete: "Profil fotosurati muvaffaqiyatli o'chirildi    ",
        },
      },
      cards: {
        title: 'Ulangan kartalar',
        attached: 'Karta muvaffaqiyatli ulandi',
        modal: 'Kartani qo’shish',
        limit: {
          error: 'Harakatlar soni tugadi',
          count: '{count}-urinish qoldi',
        },
        payments: {
          paid: "To'lov muvaffaqiyatli amalga oshirildi",
          initial: "Birinchi to'lov sanasi",
          actual: "Asosiy to'lov sanasi",
        },
      },
      acts: {
        noData: 'Sizda imzolangan aktlar yo’q',
      },
    },
    installment: {
      postponement: 'To’lov kunini • {date}',
      postponementWithShift: "To'lov sanasini ko'chirish bilan kechiktirish",
      shift: {
        info: [
          "To'lov muddati faqat bitta emas, balki",
          'barcha',
          "keyingi to'lovlar uchun siz tanlagan sanaga o'zgaradi.",
        ],
        instruction: "Agar oylik to'lov sanasini o'zgartirmoqchi bo'lsangiz, katakchani belgilang.",
      },
      commission: 'Komissiya',
      title: 'Muddatli to’lov',
      info: 'Muddatli to’lov haqida ma’lumot',
      amount: 'Muddatli to’lov summasi',
      applicationAmount: 'Ariza miqdori',
      detail: {
        title: "Muddatli to'lov tafsilotlari",
        issued: 'Berilgan',
        act: 'Aktni yuklab olish',
        receiptLink: 'Chekni ochish',
        doc: 'Hujjat',
        certificateOfClosure: "Muddatli to'lov yopilgani haqida guvohnoma",
      },
      issued: 'Qachon berilgan',
      month: 'Muddati',
      paid: {
        amount: 'Umumiy to’langan',
        month: 'Ga umumiy to’langan',
      },
      next: 'Keyingi to’lov',
      after: '{count} keyin',
      before: '{day} oldin edi',
      progress: 'To’lovlar rivoji',
      charts: {
        payments: 'To’lovlar grafiki',
        history: 'To’lovlar tarixi',
      },
      fine: {
        title:
          'O’z vaqtida to’lanmagan muddatli to’lov uchun, sizga oylik to’lovdan {penaltyPercent} jarima yozilishi mumkin',
        description: 'Muddatli to’lovni o’z vaqtida to’lashni so’raymiz',
      },
      delayed: {
        detail: 'Kechiktirish tafsilotlari',
        days: '{count} kunga surildi',
        daysShift: "Barcha to'lovlar {days} kechiktirildi",
        title: "Siz to'lovni {days} kunga kechiktirdingiz",
        description:
          "Iltimos, to'lovni amalga oshiring. O'z vaqtida to'lamaganlik uchun oylik to'lovning 1% miqdorida jarima solinadi.",
        success: 'Kechiktirish so’rovi yaratildi!',
      },
      dates: {
        payment: 'To’lov kuni',
        delay: 'Sana',
        requested: 'So’raldi',
        active: 'Yaroqlik muddati',
        shift: 'Surilishi bilan',
        delayed: 'Kunga kechiktirildi',
        overdue: "Kechiktirilgan to'lov kuningiz",
      },
      firstInstallment: 'Boshlang’ich to’lov',
      table: {
        payments: ['Sana', 'Summa', 'To’landi'],
        history: ['Summa', ' To’lov turi / Manba', 'Sana', 'To’lov'],
        penalties: ['Sana', 'Balans', 'Jarima summasi'],
      },
      payment: {
        title: "To'lov tafsilotlari",
        amount: "To'lov summasi",
        payFor: "{date} uchun to'lov",
        setAmount: "To'lov summasini kiriting",
        labels: {
          'active_payment direct debit': 'Avto to’lov',
          'problem_payment direct debit': 'Avto to’lov',
          'overdue_payment direct debit': 'Avto to’lov',
          'online direct debit': 'Alif Nasiya',
          'credits force withdraw': 'Alif аvto to’lov',
          'credits safe withdraw': 'Alif аvto to’lov',
          'call_center direct debit': 'Call markaz',
          'calls force withdraw': 'Call markaz',
          'calls safe withdraw': 'Call markaz',
          application: 'To’lov tizimi',
          bank: 'Bank',
          hold: 'Boshlang’ich to’lov',
          'penalty force withdraw': 'Jarima',
          'force withdraw': 'Alif аvto to’lov',
          'restored after error': 'Qo’lda tiklangan to’lovlar',
          'manual direct debit': 'Alif аvto to’lov',
          'withdraw_force direct debit': 'Alif аvto to’lov',
        },
      },
      noHistory: 'Sizda to’lovlar tarixi yo’q',
      penalties: {
        amount: 'Jarimalar summasi',
        info: 'Jarima kechirish so’rovini muddatIi   to’lovni yopgandan so’ng so’rasangiz bo’ladi',
        noData: 'Sizda jarima yo’q',
        totalAmount: 'Jarima umumiy summasi',
        modalTitle: 'Jarimalar',
        successRequest: "Jarimalarni kechirish to'g'risida so'rovnoma yuborildi. SMS javobini kuting.",
        success: "Jarimalar {amount} so'mga tushirildi.",
      },
      successPay: "To'lov muvaffaqiyatli amalga oshirildi",
    },
    applications: {
      status: {
        NEW: 'Yangi',
        REVIEWING: "Ko'rib chiqilmoqda",
        APPROVED: 'Tasdiqlandi',
        REJECTED: 'Rad etildi',
        COMPLETED: 'Rasmiylashtirilgan',
        CANCELLED: 'Bekor qilindi',
        DELIVERING: 'Yetkazib berishga',
        DELETED: "O'chirilgan",
      },
      modal: {
        title: 'Ariza tafsilotlari',
        storeName: "Do'kon",
        monthlyPayment: "Oylik to'lov",
        sumMonth: 'sum/oy',
        month: 'Oy',
        commission: 'Kommissiya',
        credit: 'Muddatli to’lov',
        table: {
          title: 'Tovarlar',
          name: 'Nomi',
          cost: 'Narxi',
          quantity: 'Soni',
          totalAmount: 'Umumiy summa',
        },
      },
    },
    accountReview: {
      title: `Sizning akauntingiz ko'rib chiqilmoqda`,
      text: `Limit olish uchun, ko'rib chiqish jarayoni tugashini kutishingizni so'raymiz`,
    },
    banners: {
      alifshop: {
        title: "Alif Shopda muddatli to'lovga onlayn xarid qilish",
        subTitle: 'Ostonangizgacha bepul yetkazib berish xizmati.',
        subTitle2: "O'zbekiston bo'ylab 100 dan ortiq do'konlar 1 joyda",
        button: "Alif Shopga o'tish",
      },
      partners: {
        title: "Muddatli to'lovga hamkorlar do'konlarida sotib olish",
        subTitle: 'Maslahatchining yordami.',
        subTitle2: "O'zbekiston bo'ylab 2000 dan ortiq hamkorlarimiz do'konlari",
        button: "Do'konlar ro'yxati",
      },
    },
  },
  doughnut: {
    left: 'Qoldi',
    paid: 'To’landi',
  },
  header: {
    main: 'Asosiy',
    shops: "Do'konlar",
    partner: `Hamkor bo'ling`,
    applications: 'Arizalar',
    installments: "Muddatli to'lovlar",
    cards: 'Kartalar',
    returnToCabinet: 'Shaxsiy sahifaga qaytish',
    aboutUs: 'Biz haqimizda',
  },
  fields: {
    profile: {
      newPassword: 'Yangi parol ',
      repeatNewPassword: 'Qaytadan yangi parol kiritish',
    },
    shops: {
      city: 'Shahar',
      district: 'Tumani',
      allCity: 'Hamma shaharlar',
      category: 'Toifalar',
      shopName: 'Do’kon nomi',
      noCategory: "Kategoriya yo'q",
    },
    applications: {
      title: 'Arizalar',
      amount: 'Narxi',
      date: 'Ariza sanasi',
      moreInfo: "Batafsil ma'lumot",
    },
    payment: {
      customAmount: "To'lov summasini kiritish",
    },
    amount: 'Summa',
    amountPlaceholder: 'Summani kiriting',
    other: 'Boshqa summa',
    calendar: 'Sanani tanlang',
    shift: "Barcha to'lovlar uchun",
    cause: 'Sabab',
    penalties: {
      placeholder: 'Fikringizni kiriting (ixtiyoriy)',
    },
    delays: {
      title: 'Kechiktirish',
      description: 'Tavsif kiriting (majburiy)',
      accepted: "Kechikish haqidagi so'rovingiz qabul qilindi.",
      instruction: "Iltimos, so'rovingizni ko'rib chiqgunimizcha kutib turing. Siz SMS orqali javobni olasiz.",
      why: "Muddatli to'lovni nimaga kechiktirmoqchisiz?",
    },
    errors: {
      date: "'Sana' maydoni to'ldirilishi kerak",
      reason: "'Sabab' maydoni to'ldirilishi kerak",
      note: "'Tavsif' maydoni to'ldirilishi kerak",
    },
  },
  btns: {
    profile: {
      changePhone: 'Raqamni o’zgartirish',
      photo: {
        change: 'Rasmni o’zgartirish',
        delete: 'Rasmni o’chirish',
      },
      cards: {
        add: 'Kartani qo’shish',
      },
      acts: {
        open: 'Aktni ochish',
      },
    },
    pay: 'To’lash',
    cancel: 'Bekor qilish',
    requestDeferral: "Kechiktirishni so'rash",
    request: 'So’rov qoldirish',
    requestPenalty: "Kamaytirishni so'ramoq",
    changePhone: "Raqamni o'zgartirish",
    goBackToSite: 'Saytga qaytish',
    shops: {
      spoiler: {
        visible: 'Yashirish',
        collapse: 'Hammasini ko’rsatish',
      },
      type: {
        allMobile: 'offlayn va onlayn',
        all: 'Barchasi',
        online: 'Onlayn',
        offline: 'Offlayn',
      },
    },
  },
  messages: {
    profile: {
      password: 'Yangi parol muvaffaqiyatli o’zgartirildi',
      phone: 'Telefon raqami muvaffaqiyatli almashtirildi',
    },
  },
  tabs: {
    profile: {
      cards: 'Kartalar',
      settings: 'Sozlamalar',
      acts: 'Aktlar',
    },
    installment: {
      payments: 'To’lovlar',
      information: 'Ma’lumot',
    },
    charts: {
      payments: 'To’lovlar grafiki',
      history: 'To’lovlar tarixi',
    },
  },
  modals: {
    confirmPay: "miqdoridagi to'lovingizni tasdiqlaysizm",
  },
  problemCase: {
    panel: {
      title: 'Qaysi tovar maqul kelmadi',
    },
    title: 'Qaysi tovar maqul kelmadi',
    fileInputLabel: 'Qaysi tovar yoqmadi?',
    reasonsBlockLabel: 'Sababini kiriting',
    reasons: [
      'Mahsulot nuqsonli',
      'Mahsulot menga yoqmadi',
      "Noto'g'ri mahsulot olib kelindi",
      'Mahsulot spetsifikatsiyalarga mos kelmaydi',
      'Boshqa',
    ],
    reasonsInputPlaceholder: 'Sababini kiriting',
    buttonText: "Qaytarish uchun so'rov yuboring",
    form: {
      selectFile: 'Fayllarni tanlang',
      selectAnother: 'Boshqa faylni yuklang',
      selectMore: 'Yana fayllarni qo’shish',
    },
    successText: 'Qaytarish uchun so’rov yaratildi!',
    error: {
      description: 'Kredit topilmadi',
      btn: 'Asosiy sahifaga qaytish',
    },
  },
  reject: {
    title: 'Tovarni qaytarish',
    form: {
      title: ['Qaysi tovarni qaytarishni xohlaysiz?', 'Tanlandi {count}'],
      selectFile: 'Fayllarni tanlang',
      selectAnother: 'Boshqa faylni yuklang',
      selectMore: 'Yana fayllarni qo’shish',
      reasonLabel: 'Tovarni qaytarish sababini tanlang',
      typeReason: 'Sababini kiriting',
      btn: "Qaytarish uchun so'rov yuboring",
      selected: 'Tanlangan',
    },
    modal: {
      title: 'Siz tovarni qaytarishni aniq xoxlaysizmi?',
      itemsCount: 'Tovarlar soni',
      totalAmount: 'Umumiy summa',
      btn: 'Ariza yuboring',
    },
    success: {
      title: "Tovarni qaytarish haqida so'rovingiz yuborildi",
      description: 'Iltimos, arizangiz korib chiqilishini kuting. Tez orada menejer siz bilan aloqaga chiqadi',
      btn: 'Asosiy sahifaga qaytish',
    },
    hint: {
      title: 'Qaytarishni qanday qilish kerak',
      items: ['Qaytarmoqchi bo’lgan tovaringizni belgilang', 'Tovar suratini, yoki servis markazi qarorini yuklang'],
      showExample: 'Namuna ko’rsatish',
      modal: {
        title: 'Namunalar',
        exampleTitles: ['Buzuq mahsulotning fotosurati:', 'Xizmat ko’rsatish markazining xulosasi:'],
        button: 'Tushunarl',
      },
    },
  },
  expenses: {
    panel: {
      title: 'To‘lovni to‘lamaganligingiz haqidagi ishingiz sudga topshirildi',
      button: "Xarajatlarni ko'rish",
    },
    modal: {
      title: 'Xarajatlar',
      totalAmount: 'Umumiy xarajatlar',
      name: 'Nomi',
      value: 'Jarima miqdori',
      notaryFeeExpense: 'Boshqa xarajatlar',
      stateDutyExpense: 'Davlat boji',
      postalServiceExpense: 'Pochta xarajatlari',
      courtPenaltyExpense: 'Jarima',
      notaryPenaltyExpense: 'Jarima',
      close: 'Yopish',
    },
  },
};
